<template>
  <div class="e-faq">
      <div class="e-faq__content">
        <el-collapse v-if="items.length" v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in items"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <h4 class="el-collapse-item__title">
                {{ item.question | noCategory }}
              </h4>
              <!-- <div class="el-collapse-item__subtitle">
                {{ item.question | category }}
              </div> -->
            </template>
            <div v-html="item.answer" />
          </el-collapse-item>
        </el-collapse>
        <template v-else>
          <div class="e-faq__no-items">{{ $t('system.faq.noItems') }}</div>
        </template>
      </div>
  </div>
</template>

<script>
export default {
  name: 'FaqWrapper',
  filters: {
    noCategory(value) {
      return value && value.indexOf(':') > -1
        ? value.substring(value.indexOf(':') + 2, value.length)
        : value
    },
    category(value) {
      return value && value.indexOf(':') > -1
        ? value.substring(0, value.indexOf(':'))
        : value
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    activeNames: []
  })
}
</script>

<style lang="scss">
@import '_assets/faq';
</style>
<style lang="scss" scoped>
.e-faq__inner {
  &.ps {
    overflow: auto !important;

    @include break(l) {
      overflow: hidden !important;
      @at-root .mobile {
        overflow: auto !important;
      }
    }
  }
}
</style>
